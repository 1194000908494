import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import contentPreviewData from '../../../../data/previews/content.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Content"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Content" />

      <PageNavigation links={['Options', 'Usage', 'Values']} />

      <Section title="Options">
        <Paragraph>
          Choose a content color based on content hierarchy. A clear hierarchy
          makes the interface easier to understand, and ultimately more
          effective.
        </Paragraph>
        <ComponentPreview
          name="contentOptions"
          layout="split"
          previewData={contentPreviewData.options}>
          <Paragraph>We help teams win.</Paragraph>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Content Hierarchy</SectionSubhead>
        <Paragraph>
          Color contrast isn’t the only way to create content hierarchy. Weight,
          saturation, whitespace and element size should all be used in
          conjunction with contrast to gracefully guide the user’s attention.
        </Paragraph>
        <DontDo
          dontText="use one color for all elements in a region."
          doText="use a variety of content colors and sizes for easier scanning."
          imgFilename="colors-content-hierarchy"
        />

        <SectionSubhead>Accessibility</SectionSubhead>
        <Paragraph>
          Use Contrast, Default and Subtle confidently—they all pass{' '}
          <Link href="https://www.w3.org/TR/WCAG20/">
            WCAG 2.0 contrast accessibility standards
          </Link>{' '}
          on all levels.
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/colors-content-contrast.png')}
        />

        <Paragraph>
          Nonessential <em>does not</em> and should never be used for important
          information.
        </Paragraph>
        <DontDo
          dontText="use nonessential for key content in an interface."
          doText="use nonessential to support the information hierarchy."
          imgFilename="colors-content-nonessential"
        />
      </Section>

      <Section title="Values">
        <Section>
          <SectionSubhead>Light Environment</SectionSubhead>
          <ColorDataTableDesign data={data} colorsType="light" />
        </Section>

        <Section>
          <SectionSubhead>Dark Environment</SectionSubhead>
          <ColorDataTableDesign data={data} env="dark" colorsType="dark" />
        </Section>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query contentColorsDesign($type: String = "/Content/") {
    ...colorDataDesign
  }
`;
